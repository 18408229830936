import './cart-counter-icon/index.js';
import './top-banner/index.js';
import './hamburger-icon/index.js';
import './search-product-preview/index.js';
import './shopify-search-input/index.js';
import './dynamic-header-script.js';

declare global {
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var header_event_queue: Event[];
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var header_event_listener: (event: Event)=> void;
}

function onDOMContentLoaded(_event: Event) {
  const headerLinkShop = document.getElementById('header-link-shop');
  headerLinkShop?.addEventListener('click', onHeaderLinkShopClick);

  const sideMenu = document.getElementById('shopify-section-side-menu');
  sideMenu?.classList.remove('hidden');

  addEventListener('menu-closed', onMenuClosed);
}

function onMenuClosed(event: WindowEventMap['menu-closed']) {
  const link = document.getElementById('header-link-shop');
  if (link) {
    const menu = event.detail?.name;
    if (menu && !['account-menu', 'shop-menu', 'upsell'].includes(menu)) {
      link.setAttribute('aria-expanded', 'false');
    }

    link.focus();
  }
}

function onHeaderLinkShopClick(_event: MouseEvent) {
  if (document.body.dataset.menuOpen === 'shop-menu') {
    dispatchEvent(new Event('menu-close-requested'));
  } else {
    type Detail = WindowEventMap['menu-open-requested']['detail'];
    dispatchEvent(new CustomEvent<Detail>('menu-open-requested', {
      detail: {
        name: 'shop-menu'
      }
    }));
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}

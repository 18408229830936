import './menu-element/index.js';

// This section file is loaded directly into the money back guarantee layout as a hack for
// supporting the right hand side upsell panel.

type MenuName = 'account-menu' | 'shop-menu';

type MenuOpenRequestedEvent = CustomEvent<{
  /**
   * The name of the menu to open
   */
  name: MenuName;
}>;

type MenuClosedEvent = CustomEvent<{
  name: MenuName;
}>;

type MenuOpenedEvent = CustomEvent<{
  name: MenuName;
}>;

declare global {
  interface WindowEventMap {
    'menu-close-requested': Event;
    'menu-closed': MenuClosedEvent;
    'menu-open-requested': MenuOpenRequestedEvent;
    'menu-opened': MenuOpenedEvent;
  }
}

interface BodyElement extends HTMLElement {
  readonly dataset: {
    /**
     * The currently open menu
     */
    menuOpen: MenuName;
  };
}

/**
 * This intentionally does not accept a specific menu to close because that gives the callers the
 * ability to specify an incorrect menu and requires that callers know the name. Instead, this
 * closes whatever the currently open menu is, and does nothing when there is no currently open
 * menu.
 */
function menuClose() {
  removeEventListener('shroud-clicked', menuClose);

  // Get the currently open menu, if any.
  const name = <MenuName | null | undefined>document.body.dataset.menuOpen;
  if (!name) {
    return;
  }

  (<BodyElement>document.body).removeAttribute('data-menu-open');

  const sideMenu = document.querySelector('menu-element');

  // We have to check whether the side menu is defined as not all menu close calls are made on a
  // page where the side menu is present in the dom. For example, the money back guarantee pages do
  // not have a side menu, but they do have a side cart, which still uses menu open/close for the
  // purposes of the side cart upsell section.

  // TODO: this does not belong here. now that we emit a menu closed event, this should be moved to
  // some listener that is attached from the appropriate module.

  if (sideMenu) {
    sideMenu.classList.remove('expanded');
    sideMenu.classList.add('collapsed');
    sideMenu.setAttribute('inert', '');

    type Detail = WindowEventMap['modal-close-requested']['detail'];
    const modalCloseEvent = new CustomEvent<Detail>('modal-close-requested',
      { detail: { element: sideMenu } }
    );
    dispatchEvent(modalCloseEvent);
  }

  dispatchEvent(new CustomEvent<MenuClosedEvent['detail']>('menu-closed', {
    detail: {
      name
    }
  }));

  // TODO: this does not belong here. now that we emit a menu closed event, this should be moved to
  // some listener that is attached from the appropriate module.

  const hamburgerIcon = document.querySelector('hamburger-icon');
  if (hamburgerIcon) {
    hamburgerIcon.dataset.opened = 'false';
  }

  // TODO: this does not belong here. now that we emit a menu closed event, this should be moved to
  // some listener that is attached from the appropriate module.
  sideMenu.dataset.active = 'shop-menu';
}

/**
 * Never interact with document body's data attributes directly. Always use this library, or a
 * decoration of this library.
 */
function menuOpen(menuName: MenuName) {
  if (menuName === document.body.dataset.menuOpen) {
    console.debug('menu is already open', menuName);
    return;
  }

  (<BodyElement>document.body).dataset.menuOpen = menuName;

  const menuEl = document.querySelector('menu-element');
  menuEl.removeAttribute('inert');
  menuEl.classList.remove('collapsed');
  menuEl.classList.add('expanded');
  menuEl.dataset.active = menuName;

  type Detail = WindowEventMap['modal-show-requested']['detail'];
  const modalShowEvent = new CustomEvent<Detail>('modal-show-requested',
    { detail: { element: menuEl } }
  );
  dispatchEvent(modalShowEvent);
  addEventListener('shroud-clicked', menuClose);

  dispatchEvent(new CustomEvent<MenuOpenedEvent['detail']>('menu-opened', {
    detail: {
      name: menuName
    }
  }));

  // TODO: now that we fire a menu opened event after opening the menu, we can move some of this
  // logic that does not belong here to its appropriate place by adding event listeners for the menu
  // opened event in the appropriate places.

  const hamburgerIcon = document.querySelector('hamburger-icon');
  if (hamburgerIcon) {
    hamburgerIcon.dataset.opened = 'true';
  }

  // This cannot assume that the menu element is defined. We implemented the money back
  // guarantee layout to not include the side menu in the dom. We implemented the upsell list that
  // appears when clicking the continue to checkout button to use the side menu controller logic,
  // but that controller is ideally only loaded from the side menu section where the menu element
  // is guaranteed to exist in the dom. However, to fix that, we directly load the side menu section
  // js code into the money back guarantee layout without actually loading the side menu section
  // liquid. What that means is that this section code cannot reliably assume that the menu element
  // is found in the dom.

  // We have to check for whether the m element has a scroll function, because in some older
  // browsers there is not one
  //
  // TODO: just set scrollTop (and maybe scrollLeft?), avoid using the scroll function.

  const menuElement = document.querySelector('menu-element');
  if (typeof menuElement?.scroll === 'function') {
    menuElement.scroll(0, 0);
  }
}

function onMenuOpenRequested(event: MenuOpenRequestedEvent) {
  menuOpen(event.detail.name);
}

function onMenuCloseRequested(_event: Event) {
  menuClose();
}

addEventListener('menu-open-requested', onMenuOpenRequested);
addEventListener('menu-close-requested', onMenuCloseRequested);

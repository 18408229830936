const storageKey = 'search_history';

/**
 * Appends a single value to search history
 *
 * This function is infallible.
 */
export function append(value: string) {
  if (typeof value === 'string' && value.length > 0) {
    const values = read();
    values.push(value);
    write(values);
  }
}

/**
 * Saves queries to search history.
 *
 * This does some preprocessing of the array before persisting.
 *
 * This does not error when the write fails.
 */
export function write(queries: string[]) {
  try {
    writeUnsafe(queries);
  } catch (error) {
    console.log(error);
  }
}

function writeUnsafe(queries: string[]) {
  let values: string[] = [];
  for (const query of queries) {
    if (query) {
      let value = query;
      value = value.trim();
      value = value.replace(/\s+/g, ' ');
      value = value.slice(0, 25);
      value = value.toLowerCase();
      if (!values.includes(value)) {
        values.push(value);
      }
    }
  }

  values = values.slice(-10);

  localStorage.setItem(storageKey, JSON.stringify(values));
}

function reset() {
  try {
    localStorage.removeItem(storageKey);
  } catch (error) {
    console.log(error);
  }
}

function isStringArray(value: any): value is string[] {
  return Array.isArray(value) && value.every(item => typeof item === 'string');
}

/**
 * Attempts to read search history from client side storage. This method is infallible. On error,
 * the error is logged and an empty array is returned.
 */
export function read() {
  try {
    const serialized = localStorage.getItem(storageKey);
    if (serialized) {
      const values = <string[]>JSON.parse(serialized);
      if (isStringArray(values)) {
        return values;
      }
    }
  } catch (error) {
    // Prevent poison pill
    reset();
    console.log(error);
  }

  return [];
}

interface ScrollState {
  currentPosition: number;
  direction: string;
  previousPosition: number;
}

const scrollState: ScrollState = {
  currentPosition: 0,
  previousPosition: 0,
  direction: 'down'
};

let timerId: ReturnType<typeof setTimeout>;

function onSearchButtonClick(event: MouseEvent) {
  event.stopPropagation();
  const headerWrapper = document.querySelector<HTMLElement>('.header-wrapper');
  headerWrapper.dataset.search = headerWrapper.dataset.search === 'search-hidden' ?
    'search-visible' :
    'search-hidden';

  if (headerWrapper.dataset.search === 'search-visible') {
    const searchInput = document.querySelector('shopify-search-input');
    searchInput.dataset.triggerFocus = Date.now().toString();
  }
}

function onResize(_event: Event) {
  const scrollActions = handleScrollActions(scrollState);
  if (scrollActions) {
    scrollState.currentPosition = scrollActions.currentPosition;
    scrollState.previousPosition = scrollActions.previousPosition;
    scrollState.direction = scrollActions.direction;
  }
}

function onScroll(_event: Event) {
  clearTimeout(timerId);
  timerId = setTimeout(onTimeout, 1000 / 60);
}

function onTimeout() {
  const scrollActions = handleScrollActions(scrollState);
  if (scrollActions) {
    scrollState.currentPosition = scrollActions.currentPosition;
    scrollState.previousPosition = scrollActions.previousPosition;
    scrollState.direction = scrollActions.direction;
  }
}

function onDOMContentLoaded(_event: Event) {
  // updateHeaderPromoVisibility expects to be able to access the dom, so we must wait to fire it
  // until the dom is queryable.

  const searchButton = document?.querySelector<HTMLElement>('button.search-icon');
  searchButton?.addEventListener('click', onSearchButtonClick);

  addEventListener('resize', onResize, { passive: true });
  addEventListener('scroll', onScroll, { passive: true });
}

/**
 * Beware this is run on some pages without a header class so it returns early with undefined,
 * meaning that the caller cannot assume the return value is a defined object.
 */
function handleScrollActions(scrollState: ScrollState) {
  const header = document.querySelector<HTMLElement>('.header-wrapper');
  if (!header) {
    return;
  }

  const headerHeight = header.offsetHeight;

  const containingElement = <HTMLElement> (document.documentElement || document.body.parentNode ||
    document.body);
  const currentPosition = scrollY || containingElement.scrollTop;
  const previousPosition = scrollState.currentPosition;
  const direction = currentPosition >= previousPosition ? 'down' : 'up';

  // `setAttribute` is used instead of `dataset` so that CSS based on data attributes is accessible
  // to the CSS parser. `dataset` accounts for both element props and attributes, but CSS can only
  // read inline attributes. Also note that dev tools may show the attributes in the DOM even if
  // they aren't actually explicit attributes that CSS can read.
  //
  // Some instances that didn't leverage CSS were also reverted to `setAttribute` to have this file
  // be consistent.
  //
  // TODO: verify the above comment

  if (currentPosition === 0) {
    header.setAttribute('data-state', 'initial');
    header.setAttribute('data-scroll-position', currentPosition.toString());
    header.removeAttribute('data-scroll-direction');
  } else if (currentPosition > 0) {
    const scrollTolerance = 100;

    header.setAttribute('data-scroll-position', currentPosition.toString());
    header.setAttribute('data-scroll-direction', direction);

    const headerPromo = document.querySelector<HTMLElement>('.header-promo');
    const headerBanner = header.querySelector<HTMLElement>('.header-banner');
    const headerBannerVisible = headerBanner && !headerBanner?.classList.contains('hidden');
    const headerPromoHeight = headerPromo ? headerPromo.offsetHeight : 0;

    const headerWrapper = document.querySelector<HTMLElement>('.header-wrapper');
    const countryISOCode = headerWrapper.dataset.country || 'US';

    // Hide the promo if needed
    if (countryISOCode === 'US' && headerBannerVisible && direction === 'down' &&
      currentPosition > headerPromoHeight) {
      header.setAttribute('data-state', 'promo-hidden');
    }

    // Show the promo if needed
    if (headerBannerVisible && direction === 'up' &&
      currentPosition <= headerHeight + scrollTolerance) {
      header.setAttribute('data-state', 'promo-visible');
    }

    // Hide the search bar if needed
    if (direction === 'down' && currentPosition >= previousPosition + scrollTolerance / 2) {
      header.setAttribute('data-search', 'search-hidden');
    }
  }

  return {
    currentPosition,
    previousPosition,
    direction
  };
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}

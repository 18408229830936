import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';
import * as api from './predictive-search-api.js';
import * as SearchHistory from './search-history.js';

/**
 * Renders the search input and the search input results preview.
 */
class ShopifySearchInput extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-trigger-focus'];
  }

  readonly dataset!: {
    /**
     * Boolean represented as string to indicate whether user is on search results page
     */
    isSearchPage: string;

    /**
     * Mechanism to externally focus the user on the input element
     */
    triggerFocus: string;
  };

  private onFormSubmitBound = this.onFormSubmit.bind(this);
  private onFocusBound = this.onFocus.bind(this);
  private onDocumentClickBound = this.onDocumentClick.bind(this);
  private onSearchCloseBound = this.onSearchClose.bind(this);
  private onKeyUpBoundDebounced = debounce(this.onKeyUp.bind(this));
  private onKeyDownBound = this.onKeyDown.bind(this);
  private onBlurBound = this.onBlur.bind(this);
  private onProductPreviewClickBound = this.onProductPreviewClick.bind(this);
  private suggestionActiveIndex = 0;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  connectedCallback() {
    const iconReset = this.shadowRoot.querySelector('.icon-close');
    iconReset.addEventListener('click', this.onSearchCloseBound);

    const searchInput = this.shadowRoot.querySelector<HTMLInputElement>('input');
    searchInput.addEventListener('keyup', this.onKeyUpBoundDebounced);
    searchInput.addEventListener('keydown', this.onKeyDownBound);
    searchInput.addEventListener('focus', this.onFocusBound);
    searchInput.addEventListener('blur', this.onBlurBound);
    searchInput.addEventListener('click', onInputClick);

    if (searchInput.value.length === 0) {
      this.shadowRoot.querySelector<HTMLButtonElement>('.icon-search').disabled = true;
    }

    const formEl = this.shadowRoot.querySelector('form');
    formEl.addEventListener('submit', this.onFormSubmitBound);

    if (this.dataset.isSearchPage === 'true') {
      iconReset.classList.add('visible');

      const urlParams = new URLSearchParams(location.search);
      const searchTerm = urlParams.get('q');
      searchInput.value = searchTerm;

      // The search parameter is not guaranteed to exist on the search page so this optional
      // chaining is important.

      if (searchTerm?.length > 0) {
        const searchIcon = this.shadowRoot.querySelector<HTMLButtonElement>('.icon-search');
        searchIcon.disabled = true;
        this.suggest(searchInput.value, false).catch(handleSuggestError);
      }

      this.renderPreviewPane(searchInput.value, [], []);
    }
  }

  public attributeChangedCallback(name: string, _oldValue: string, _newValue: string) {
    if (name === 'data-trigger-focus') {
      const searchInput = this.shadowRoot.querySelector<HTMLInputElement>('input');
      searchInput.focus();
    }
  }

  public disconnectedCallback() {
    const iconReset = this.shadowRoot.querySelector('.icon-close');
    iconReset?.removeEventListener('click', this.onSearchCloseBound);

    const searchInput = this.shadowRoot.querySelector<HTMLInputElement>('input');
    searchInput?.removeEventListener('keyup', this.onKeyUpBoundDebounced);
    searchInput?.removeEventListener('keydown', this.onKeyDownBound);
    searchInput?.removeEventListener('focus', this.onFocusBound);
    searchInput?.removeEventListener('blur', this.onBlurBound);
    searchInput?.removeEventListener('click', onInputClick);

    const formEl = this.shadowRoot.querySelector('form');
    formEl?.removeEventListener('submit', this.onFormSubmitBound);
  }

  private showSearchPreview() {
    document.documentElement.classList.add('js-no-scroll');

    const suggestionEl = this.shadowRoot.querySelector<HTMLUListElement>('ul');
    suggestionEl.classList.add('visible');

    const iconReset = this.shadowRoot.querySelector('.icon-close');
    iconReset.classList.add('visible');

    document.addEventListener('click', this.onDocumentClickBound);

    const formEl = this.shadowRoot.querySelector('form');
    formEl.ariaExpanded = 'true';
    formEl.classList.add('visible');

    const headerEl = document.querySelector<HTMLElement>('.header-wrapper');
    if (headerEl) {
      headerEl.dataset.search = 'search-visible';
    }
  }

  private hideSearchPreview() {
    document.documentElement.classList.remove('js-no-scroll');

    const suggestion = this.shadowRoot.querySelector<HTMLUListElement>('ul');
    suggestion.classList.remove('visible');

    document.removeEventListener('click', this.onDocumentClickBound);

    const form = this.shadowRoot.querySelector('form');
    form.classList.remove('visible');

    if (this.dataset.isSearchPage !== 'true') {
      const reset = this.shadowRoot.querySelector('.icon-close');
      reset.classList.remove('visible');

      const header = document.querySelector<HTMLElement>('.header-wrapper');
      if (header) {
        header.dataset.search = 'search-hidden';
      }
    }
  }

  private onSearchClose(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.hideSearchPreview();

    const input = this.shadowRoot.querySelector<HTMLInputElement>('input');
    input.value = '';

    if (this.dataset.isSearchPage === 'true' || window.innerWidth >= 1024) {
      input.focus();
    }

    const button = this.shadowRoot.querySelector<HTMLButtonElement>('.icon-search');
    button.disabled = true;

    const resetEvent = new CustomEvent<null>('search-input-reset');
    dispatchEvent(resetEvent);
  }

  private onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.stopPropagation();
      if (this.suggestionActiveIndex === 10) {
        return;
      }

      this.suggestionActiveIndex++;
      if (this.suggestionActiveIndex === 1) {
        const suggestionEl = this.shadowRoot.querySelector('a.search-preview-link');
        if (suggestionEl) {
          suggestionEl.classList.add('active');
        }
      } else {
        this.markSuggestionActiveAtIndex(this.suggestionActiveIndex);
      }
    } else if (event.key === 'ArrowUp') {
      event.stopPropagation();
      if (this.suggestionActiveIndex === 0) {
        return;
      }

      this.suggestionActiveIndex--;
      if (this.suggestionActiveIndex === 0) {
        this.shadowRoot.querySelector('a.active')?.classList.remove('active');
        this.shadowRoot.querySelector<HTMLInputElement>('input').focus();
      } else {
        this.markSuggestionActiveAtIndex(this.suggestionActiveIndex);
      }
    } else if (event.key === 'Enter') {
      const activeAnchor =
        this.shadowRoot.querySelector<HTMLAnchorElement>('a.search-preview-link.active');
      if (activeAnchor) {
        event.preventDefault();
        activeAnchor.click();
      }
    }
  }

  private onKeyUp(event: KeyboardEvent) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      return;
    }

    const searchInput = this.shadowRoot.querySelector<HTMLInputElement>('input');
    const searchButton = this.shadowRoot.querySelector<HTMLButtonElement>('.icon-search');
    if (searchInput.value.length === 0) {
      searchButton.disabled = true;
      return;
    }

    if (this.dataset.isSearchPage) {
      const url = new URL(location.href);

      // The q parameter is not guaranteed to be found, so this may be undefined.
      const urlQueryParam = url.searchParams.get('q');

      if (urlQueryParam && searchInput.value === urlQueryParam) {
        // The button remains disabled because the input has not changed
        searchButton.disabled = true;
      } else {
        searchButton.disabled = false;
      }
    } else {
      // On all pages other than the search page, enable the search button on load.
      searchButton.disabled = false;
    }

    // TODO: is this dead code?
    const searchURL = new URL('search', `https://${location.hostname}`);
    searchURL.searchParams.append('q', searchInput.value);

    if (event.key === 'Escape') {
      this.hideSearchPreview();
    } else if (searchInput.value.length >= 3) {
      if (searchInput.value.length >= 3) {
        this.suggest(searchInput.value).catch(handleSuggestError);
      }

      // TODO: Add event and payload data
    } else if (searchInput.value.length >= 1) {
      this.renderPreviewPane(searchInput.value, [], []);
      this.showSearchPreview();
    } else {
      this.renderPreviewPane('', [], []);
      this.showSearchPreview();
    }
  }

  private onDocumentClick(event: Event) {
    const target = <HTMLElement>event.target;
    if (!target.closest('.results-container')) {
      this.hideSearchPreview();
    }
  }

  private onFocus(event: FocusEvent) {
    event.stopPropagation();
    const formEl = this.shadowRoot.querySelector('form');
    formEl.classList.add('focus');

    const searchInput = this.shadowRoot.querySelector<HTMLInputElement>('input');
    if (searchInput.value.length >= 1) {
      this.showSearchPreview();
    }
  }

  private onBlur(_event?: FocusEvent) {
    const formEl = this.shadowRoot.querySelector('form');
    formEl.classList.remove('focus');
  }

  private onFormSubmit(_event: SubmitEvent) {
    const input = this.shadowRoot.querySelector<HTMLInputElement>('input');
    const query = input.value;

    SearchHistory.append(query);

    const searchEvent = new CustomEvent<SearchedEvent['detail']>('searched', {
      detail: { query }
    });
    dispatchEvent(searchEvent);
  }

  private async suggest(query: string, showPreviewDrawer = true) {
    const body = await api.suggest({
      query,
      resource_types: ['product', 'query'],
      limit: 4,
      limit_scope: 'each',
      prefix: 'last'
    });

    const results = body.resources.results;
    this.renderPreviewPane(query, results.queries, results.products);

    if (showPreviewDrawer) {
      this.showSearchPreview();
    }
  }

  private renderPreviewPane(query: string, suggestions: api.Query[],
    products: api.Product[]) {
    const previewEl = this.shadowRoot.querySelector('ul');
    previewEl.innerHTML = '';

    let position = 1;
    let filteredQueries: api.Query[] = [];

    if (suggestions.length > 1) {
      filteredQueries = suggestions.filter(
        q => q.text !== query);

      if (filteredQueries.length > 0) {
        const liHeaderEl = document.createElement('li');
        liHeaderEl.textContent = 'Suggestions';
        liHeaderEl.classList.add('search-preview-heading');
        liHeaderEl.role = 'heading';
        liHeaderEl.ariaLevel = '3';
        liHeaderEl.tabIndex = 0;
        previewEl.appendChild(liHeaderEl);

        for (const suggestedQuery of filteredQueries) {
          const liEl = createSearchQuerySuggestionListElement(suggestedQuery, position, query);
          previewEl.appendChild(liEl);
          position++;
        }
      }
    }

    if (products.length > 0) {
      const liHeaderEl = document.createElement('li');
      liHeaderEl.textContent = 'Products';
      liHeaderEl.classList.add('search-preview-heading');
      liHeaderEl.role = 'heading';
      liHeaderEl.ariaLevel = '3';
      liHeaderEl.tabIndex = 0;
      previewEl.appendChild(liHeaderEl);

      for (const product of products) {
        const liEl = this.createProductSuggestionListElement(product, position, query);
        previewEl.appendChild(liEl);
        position++;
      }
    }

    const searchHistoryItemsToRender = 10 - (filteredQueries.length + products.length);

    if (searchHistoryItemsToRender > 0) {
      // Retrieve search history and filter out any items matching current user initiate query
      let searchHistory = SearchHistory.read();
      searchHistory = searchHistory.filter(q => q !== query);

      // Retrieve array of strings from api.Query object to compare against search history
      const suggestedTermsArray = filteredQueries.map(q => q.text);

      // Filter out all search history items that are already included in the suggestions
      searchHistory = searchHistory.filter(q => !suggestedTermsArray.includes(q));

      // Filter out all search history items that are a subset of a higher specificity user searched
      // term
      searchHistory = searchHistory.filter(q => !query.includes(q));

      if (searchHistory.length > searchHistoryItemsToRender) {
        // Slice required amount of history items required to render 10 total preview items
        searchHistory = searchHistory.slice(-searchHistoryItemsToRender);
      }

      if (searchHistory.length > 0) {
        const liHeaderEl = document.createElement('li');
        liHeaderEl.textContent = 'Search History';
        liHeaderEl.classList.add('search-preview-heading');
        liHeaderEl.role = 'heading';
        liHeaderEl.ariaLevel = '3';
        liHeaderEl.tabIndex = 0;
        previewEl.appendChild(liHeaderEl);

        for (const search of searchHistory) {
          const liEl = createSearchHistoryListElement(search, position);
          previewEl.appendChild(liEl);
          position++;
        }
      }
    }

    if (query.length) {
      let userQuery = suggestions.find(q => q.text === query);
      if (!userQuery) {
        userQuery = {
          text: query,
          styled_text: query,
          url: `/search?q=${query}`
        };
      }

      const anchorEl = document.createElement('a');
      anchorEl.href = userQuery.url;
      anchorEl.dataset.searchTerm = userQuery.text;
      anchorEl.textContent = 'Search';
      anchorEl.addEventListener('click', onSearchForClick);

      const liEl = document.createElement('li');
      liEl.classList.add('search-cta');
      liEl.appendChild(anchorEl);

      previewEl.appendChild(liEl);
    }
  }

  private createProductSuggestionListElement(product: api.Product, position: number,
    currentQuery: string) {
    const preview = document.createElement('search-product-preview');
    preview.dataset.imageUrl = product.featured_image.url;
    preview.dataset.title = product.title;

    const anchor = document.createElement('a');
    anchor.href = createSuggestedProductUrl(product.handle, position, currentQuery);
    anchor.ariaLabel = `See product details for ${product.title}`;
    anchor.dataset.searchTerm = currentQuery;
    anchor.classList.add('search-preview-link');
    anchor.classList.add('product');
    anchor.addEventListener('click', this.onProductPreviewClickBound);
    anchor.appendChild(preview);

    const item = document.createElement('li');
    item.classList.add('search-preview-item');
    item.appendChild(anchor);

    return item;
  }

  private onProductPreviewClick(_event: Event) {
    const input = this.shadowRoot.querySelector<HTMLInputElement>('input');
    SearchHistory.append(input.value);

    type Detail = SearchedEvent['detail'];
    const searchEvent = new CustomEvent<Detail>('searched', {
      detail: { query: input.value }
    });
    dispatchEvent(searchEvent);
  }

  private markSuggestionActiveAtIndex(index: number) {
    const old = this.shadowRoot.querySelector('a.search-preview-link.active');
    if (old) {
      old.classList.remove('active');
    }

    const anchors =
      this.shadowRoot.querySelectorAll('li.search-preview-item a.search-preview-link');
    const adjustedIndex = index - 1;
    const anchorEl = Array.from(anchors)[adjustedIndex];

    // TODO: an anchor element should always be found but there are errors indicating sometimes it
    // is not found. investigate this bug. for now we use optional chaining to suppress the error
    // and we silently fail to mark as active.

    anchorEl?.classList.add('active');
  }
}

/**
 * @todo do not incorporate search history in UK market
 */
function createSearchHistoryListElement(query: string, position: number) {
  const anchor = document.createElement('a');

  // TODO: this has a bug. the second parameter should be the query that will be used if the
  // person clicks on the link. the third parameter should be the query someone typed in, not the
  // suggested query. because of this bug, psq is the same as q, which is messing up the stats. i
  // am not sure why this was implemented incorrectly. i think we might need an additional param
  // to createSearchHistoryListElement, e.g. originalQuery, so that we have access to both what
  // was typed and what was suggested here, not only what was suggested.

  anchor.href = createSuggestedSearchTermUrl(position, query, query);
  anchor.textContent = query;
  anchor.dataset.searchTerm = query;
  anchor.classList.add('search-preview-link');
  anchor.ariaLabel = `See all results for "${query}"`;
  anchor.addEventListener('click', onSearchHistoryClick);

  const item = document.createElement('li');
  item.classList.add('search-preview-item');
  item.appendChild(anchor);

  return item;
}

function handleSuggestError(error: any) {
  console.warn(error);
  renderErrorMessage();
}

function renderErrorMessage() {
  const resultList = document.querySelector('.results .overflow ol');
  if (!resultList) {
    return;
  }

  const liEl = document.createElement('li');
  const headerEl = document.createElement('h4');
  headerEl.textContent = 'Oh no! There was a problem searching our products. Please try ' +
  'again in a few moments.';
  liEl.appendChild(headerEl);

  resultList.appendChild(liEl);

  // TODO: Add event and payload data
}

function createSuggestedSearchTermUrl(position: number, suggestedQuery: string,
  currentQuery: string) {
  const url = new URL('/search', location.origin);
  url.searchParams.set('_pos', position.toString());
  url.searchParams.set('_psq', currentQuery);
  url.searchParams.set('_ss', 'e');
  url.searchParams.set('_v', '1.0');
  url.searchParams.set('q', suggestedQuery);
  return url.href;
}

function createSuggestedProductUrl(productHandle: string, position: number, currentQuery: string) {
  const url = new URL(`/products/${productHandle}`, location.origin);
  url.searchParams.set('_pos', position.toString());
  url.searchParams.set('_psq', currentQuery);
  url.searchParams.set('_ss', 'e');
  url.searchParams.set('_v', '1.0');

  return url.href;
}

function createSearchQuerySuggestionListElement(query: api.Query, position: number,
  currentQuery: string) {
  const anchor = document.createElement('a');
  anchor.href = createSuggestedSearchTermUrl(position, query.text, currentQuery);
  anchor.innerHTML = query.styled_text;
  anchor.dataset.searchTerm = query.text;
  anchor.classList.add('search-preview-link');
  anchor.classList.add('suggestion');
  anchor.ariaLabel = `See all results for "${query.text}"`;
  anchor.addEventListener('click', onSuggestedQueryClick);

  const item = document.createElement('li');
  item.classList.add('search-preview-item');
  item.appendChild(anchor);

  return item;
}

/**
 * This listener prevents propagation of the click event when a user focuses on the search input
 * field. This is to prevent the event from bubbling up to the document click listener, as this
 * logic will close the search preview pane if the click was outside of the preview pane bounds.
 * Although this is the desired behavior, the one exception is the input field which should
 * not close the search preview pane if it is open and inversely, allow the on focus event handler
 * determine if there was a previously active search preview results and search term and show the
 * search preview results if so.
 */
function onInputClick(event: Event) {
  event.stopPropagation();
}

function onSearchForClick(event: Event) {
  const anchor = <HTMLAnchorElement>event.target;
  const query = anchor.dataset.searchTerm;
  SearchHistory.append(query);

  type Detail = SearchedEvent['detail'];
  const searchEvent = new CustomEvent<Detail>('searched', {
    detail: { query }
  });
  dispatchEvent(searchEvent);
}

function onSearchHistoryClick(event: Event) {
  const anchor = <HTMLAnchorElement>event.target;
  const query = anchor.dataset.searchTerm;

  type Detail = SearchedEvent['detail'];
  const searchEvent = new CustomEvent<Detail>('searched', {
    detail: { query }
  });
  dispatchEvent(searchEvent);
}

function onSuggestedQueryClick(event: Event) {
  const anchor = <HTMLAnchorElement>event.target;
  const query = anchor.dataset.searchTerm;
  SearchHistory.append(query);

  type Detail = SearchedEvent['detail'];
  const searchEvent = new CustomEvent<Detail>('searched', {
    detail: { query }
  });
  dispatchEvent(searchEvent);
}

function debounce<F extends (...params: any[])=> void>(func: F, timeout = 300) {
  let timer: ReturnType<typeof setTimeout> = null;
  return (...args: unknown[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), timeout);
  };
}

/**
 * Fired before issuing api call.
 */
type SearchStartedEvent = CustomEvent<undefined>;

/**
 * Fired when the user clears the content of the search input.
 */
type SearchInputResetEvent = CustomEvent<undefined>;

interface SearchedEventDetail {
  error?: any;

  event_id?: string;

  query: string;

  /**
   * @todo downstream relies on this but it is not yet being populated on dispatch, it used to be
   * populated when using SearchSpring but it is not with Shopify search
   */
  results?: any[];
}

type SearchedEvent = CustomEvent<SearchedEventDetail>;

declare global {
  interface WindowEventMap {
    'search-input-reset': SearchInputResetEvent;
    'search-started': SearchStartedEvent;
    searched: SearchedEvent;
  }

  interface HTMLElementTagNameMap {
    'shopify-search-input': ShopifySearchInput;
  }
}

if (!customElements.get('shopify-search-input')) {
  customElements.define('shopify-search-input', ShopifySearchInput);
}

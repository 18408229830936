// Image Helper Functions
// -----------------------------------------------------------------------------
// https://github.com/Shopify/slate.git.
//
// Integra forked this from Shopify Theme Images library so that we can support the webp image
// format.

/**
 * Preloads an image in memory and uses the browsers cache to store it until needed.
 *
 * @param images - A list of image urls
 * @param size - A shopify image size attribute
 */
export function preload(images: string[] | string, size?: string) {
  const effectiveImages = typeof images === 'string' ? [images] : images;
  for (const image of effectiveImages) {
    loadImage(getSizedImageUrl(image, size));
  }
}

/**
 * Loads and caches an image in the browsers cache.
 *
 * @param path - An image url
 */
export function loadImage(path: string) {
  const image = new Image();
  image.src = path;
}

/**
 * Find the Shopify image attribute size
 */
export function imageSize(src: string) {
  const match = src.match(
    /.+_((?:pico|icon|thumb|small|compact|medium|large|grande)|\d{1,4}x\d{0,4}|x\d{1,4})[_\.@]/
  );

  if (match) {
    return match[1];
  } else {
    return null;
  }
}

/**
 * Adds a Shopify size attribute to a URL
 */
export function getSizedImageUrl(src: string, size?: string) {
  if (size === null) {
    return src;
  }

  if (size === 'master') {
    return removeProtocol(src);
  }

  // we modified this regex to include image format webp
  const match = src.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif|webp)(\?v=\d+)?$/i);

  if (match) {
    const prefix = src.split(match[0]);
    const suffix = match[0];

    return removeProtocol(`${prefix[0]}_${size}${suffix}`);
  } else {
    console.warn('unknown extension', src);
    return null;
  }
}

export function removeProtocol(path: string) {
  return path.replace(/http(s)?:/, '');
}

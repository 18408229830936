import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * @todo cart seems to be no longer in use, but there is logic checking for it in cart drawer,
 * investigate and possibly remove
 */
type MenuName = 'account-menu' | 'cart' | 'shop-menu' | 'upsell';

/**
 * Renders the main menu. Controls the expanding and collapsing of menu sections.
 *
 * @todo this needs to somehow not directly import the menu library
 */
class MenuElement extends HTMLElement implements CustomElement {
  private onShowNavigationClickBound = this.onShowNavigationClick.bind(this);
  private onShowAccountClickBound = this.onShowAccountClick.bind(this);
  private onDOMContentLoadedBound = this.onDOMContentLoaded.bind(this);

  readonly dataset!: {
    /**
     * Currently active menu to display
     */
    active: MenuName;
  };

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      setTimeout(this.onDOMContentLoadedBound);
    } else {
      addEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
    }
  }

  public disconnectedCallback() {
    const secondaryLinks = this.querySelectorAll<HTMLElement>('.secondary-menu-link');
    for (const link of secondaryLinks) {
      link.removeEventListener('click', onSecondaryLinkClick);
    }

    const closeMenus = this.shadowRoot.querySelectorAll<HTMLElement>('.close-menu');
    for (const menu of closeMenus) {
      menu.removeEventListener('click', onCloseMenuClick);
      menu.removeEventListener('keyup', onCloseMenuKeyup);
    }

    const showAccount = this.querySelector<HTMLButtonElement>('#show-account');
    showAccount?.removeEventListener('click', this.onShowAccountClickBound);

    const showNavigation = this.querySelector<HTMLButtonElement>('#show-navigation');
    showNavigation?.removeEventListener('click', this.onShowNavigationClickBound);

    removeEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
  }

  private onDOMContentLoaded(_event: Event) {
    const secondaryLinks = this.querySelectorAll<HTMLElement>('.secondary-menu-link');
    for (const link of secondaryLinks) {
      link.addEventListener('click', onSecondaryLinkClick);
    }

    const showAccount = this.querySelector<HTMLButtonElement>('#show-account');
    showAccount?.addEventListener('click', this.onShowAccountClickBound);

    const showNavigation = this.querySelector<HTMLButtonElement>('#show-navigation');
    showNavigation?.addEventListener('click', this.onShowNavigationClickBound);

    const closeMenus = this.querySelectorAll<HTMLElement>('.close-menu');
    for (const menu of closeMenus) {
      menu.addEventListener('click', onCloseMenuClick);
      menu.addEventListener('keyup', onCloseMenuKeyup);
    }
  }

  private onShowAccountClick(_event: MouseEvent) {
    type Detail = WindowEventMap['menu-open-requested']['detail'];
    dispatchEvent(new CustomEvent<Detail>('menu-open-requested', {
      detail: {
        name: 'account-menu'
      }
    }));

    const showAccountMenuButton = this.querySelector('#show-account');
    showAccountMenuButton?.setAttribute('aria-expanded', 'true');

    const backToShopMenuButton = this.querySelector('#show-navigation');
    backToShopMenuButton?.setAttribute('aria-expanded', 'true');
  }

  private onShowNavigationClick(_event: MouseEvent) {
    type Detail = WindowEventMap['menu-open-requested']['detail'];
    dispatchEvent(new CustomEvent<Detail>('menu-open-requested', {
      detail: {
        name: 'shop-menu'
      }
    }));

    const accountButton = this.querySelector('#show-account');
    accountButton?.setAttribute('aria-expanded', 'false');

    const navButton = this.querySelector('#show-navigation');
    navButton?.setAttribute('aria-expanded', 'false');
  }
}

function onCloseMenuClick(_event: MouseEvent) {
  dispatchEvent(new Event('menu-close-requested'));
}

function onCloseMenuKeyup(event: KeyboardEvent) {
  if (event.key === 'Enter') {
    dispatchEvent(new Event('menu-close-requested'));
  }
}

function onSecondaryLinkClick(event: MouseEvent) {
  const element = <HTMLButtonElement>event.currentTarget;
  if (element.getAttribute('aria-expanded') === 'true') {
    element.setAttribute('aria-expanded', 'false');
  } else {
    element.setAttribute('aria-expanded', 'true');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'menu-element': MenuElement;
  }
}

if (!customElements.get('menu-element')) {
  customElements.define('menu-element', MenuElement);
}

import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders the hamburger icon. Opens the main menu drawer when clicked.
 */
class HamburgerIcon extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-opened'
    ];
  }

  readonly dataset!: {
    /**
     * Whether the menu is open
     */
    opened: 'false' | 'true' | null;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.addEventListener('click', onHamburgerButtonClick);
    this.render();
  }

  public disconnectedCallback() {
    this.removeEventListener('click', onHamburgerButtonClick);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const hamburgerButton = this.shadowRoot.querySelector('button');
    if (this.dataset.opened === 'true') {
      hamburgerButton?.setAttribute('aria-expanded', 'true');
    } else {
      hamburgerButton?.setAttribute('aria-expanded', 'false');
    }
  }
}

function onHamburgerButtonClick(_event: MouseEvent) {
  if (document.body.dataset.menuOpen === 'account-menu') {
    dispatchEvent(new Event('menu-close-requested'));
  } else if (document.body.dataset.menuOpen === 'shop-menu') {
    dispatchEvent(new Event('menu-close-requested'));
  } else {
    type Detail = WindowEventMap['menu-open-requested']['detail'];
    dispatchEvent(new CustomEvent<Detail>('menu-open-requested', {
      detail: {
        name: 'shop-menu'
      }
    }));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'hamburger-icon': HamburgerIcon;
  }
}

if (!customElements.get('hamburger-icon')) {
  customElements.define('hamburger-icon', HamburgerIcon);
}

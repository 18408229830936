import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders generic Lange full width banner with title, copy, cta.
 *
 * @todo this is currently re-rendering numerous times which is thrashing the dom, this needs more
 * logic so that we only render when needed
 */
class BannerElement extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-text-color',
      'data-promo-code',
      'data-title',
      'data-copy',
      'data-cta-url',
      'data-cta-text',
      'data-disclaimer',
      'data-full-banner-is-cta'
    ];
  }

  readonly dataset!: {
    /**
     * Displays banner copy (subtitle)
     */
    copy: string;
    /**
     * Cta button hash, if provided cta href is set to hash value, overrides ctaUrl attribute
     */
    ctaHash: string;
    /**
     * Cta button name
     */
    ctaText: string;
    /**
     * Cta button url redirect
     */
    ctaUrl: string;
    /**
     * Displays additional banner disclaimer text
     */
    disclaimer: string;
    /**
     * Boolean informs is whole banner area a cta or not
     */
    fullBannerIsCta: string;

    /**
     * Define heading level. Default is <h2>.
     */
    headingLevel: 'h1' | 'h2';

    /**
     * Flag to remove any bottom margin when required for the UX
     */
    noBottomMargin: 'false' | 'true';

    /**
     * Displays promo code on the banner
     */
    promoCode: string;
    /**
     * Banner text color represented as hex
     */
    textColor: string;
    /**
     * Displays banner title
     */
    title: string;
  };

  public shadowRoot!: ShadowRoot;
  private onBannerClickedBound = this.onBannerClicked.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  public connectedCallback() {
    this.addEventListener('click', this.onBannerClickedBound);
    this.render();
  }

  public disconnectedCallback() {
    this.removeEventListener('click', this.onBannerClickedBound);
  }

  private onBannerClicked() {
    if (this.dataset.fullBannerIsCta === 'true') {
      location.href = this.dataset.ctaUrl;
    }
  }

  private render() {
    const disclaimerWrapper = this.shadowRoot.querySelector<HTMLDivElement>('.disclaimer');
    const disclaimer = disclaimerWrapper.querySelector('p');

    if (this.dataset.disclaimer) {
      disclaimerWrapper.style.display = 'flex';
      disclaimer.textContent = this.dataset.disclaimer;
    } else {
      disclaimerWrapper.style.display = 'none';
    }

    let titleEl = this.shadowRoot.querySelector('.title');
    if (!titleEl) {
      const titleTag = this.dataset.headingLevel === 'h1' ? 'h1' : 'h2';
      titleEl = document.createElement(titleTag);
      titleEl.setAttribute('data-xid', 'hero-cta-title');
      titleEl.classList.add('title');

      const contentEl = this.shadowRoot.querySelector('.content');
      contentEl.prepend(titleEl);
    }

    titleEl.innerHTML = this.dataset.title;

    const copyEl = this.shadowRoot.querySelector('.copy');
    copyEl.innerHTML = this.dataset.copy;

    const ctaAnchor = this.shadowRoot.querySelector<HTMLAnchorElement>('.cta a');
    if ((this.dataset.ctaUrl || this.dataset.ctaHash) && this.dataset.ctaText) {
      ctaAnchor.style.display = 'inline-block';
      ctaAnchor.textContent = this.dataset.ctaText;
      ctaAnchor.ariaLabel = this.dataset.ctaText || '';
      ctaAnchor.href = this.dataset.ctaHash ? `#${this.dataset.ctaHash}` : this.dataset.ctaUrl;
    } else {
      ctaAnchor.style.display = 'none';
    }

    // If a CTA URL is provided without button text, we make the whole banner a link.
    if (this.dataset.ctaUrl && !this.dataset.ctaText || this.dataset.fullBannerIsCta === 'true') {
      this.ariaLabel = this.dataset.title;
      this.role = 'link';
    } else {
      this.removeAttribute('aria-label');
      this.removeAttribute('role');
    }

    // Only show promo-code section if there is a promo code
    const promoCodeWrapperEl = this.shadowRoot.querySelector<HTMLDivElement>('.promo-code');
    if (this.dataset.promoCode) {
      const promoCodeEl = promoCodeWrapperEl.querySelector('span');
      promoCodeWrapperEl.style.display = 'inline-flex';
      promoCodeEl.innerText = this.dataset.promoCode;
    } else {
      promoCodeWrapperEl.style.display = 'none';
    }

    if (this.dataset.textColor) {
      const copyWrapperEl = this.shadowRoot.querySelector<HTMLDivElement>('.copy');
      copyWrapperEl.style.color = this.dataset.textColor;
      disclaimerWrapper.style.color = this.dataset.textColor;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'banner-element': BannerElement;
  }
}

if (!customElements.get('banner-element')) {
  customElements.define('banner-element', BannerElement);
}

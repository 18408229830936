import * as Cookie from '@integrabeauty/cookie';
import type { CustomElement } from '@integrabeauty/custom-elements';
import { startTimer, timeLeft } from '../../../lib/timer.js';
import { write as writeCookie } from './cookie-defaults.js';
import html from './index.html';
import styles from './index.scss';

class TopBanner extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * Should the badge be displayed
     */
    badgeShow: 'false' | 'true';

    /**
     * The text of the clock badge.
     *
     * @default "Limited Time"
     */
    badgeText: string;

    /**
     * Stop time as unix epoch time.
     *
     * @see https://www.epochconverter.com
     */
    clockEndTime: string;

    /**
     * Set visual style for countdown clock, change it from default numbers to square flip or
     * circles.
     *
     * @todo not functional yet
     */
    clockFaceStyle: string;

    /**
     * If false the entire clock and the "Limited time" badge will disappear
     */
    clockShow: 'false' | 'true';

    /**
     * Start time as unix epoch time.
     *
     * @see https://www.epochconverter.com
     */
    clockStartTime: string;
  };

  public shadowRoot!: ShadowRoot;
  private endTimestamp: number | null = null;

  private onCloseClickedBound = this.onCloseClicked.bind(this);
  private onTimerTickBound = this.onTimerTick.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    if (this.dataset.clockEndTime) {
      this.endTimestamp = parseInt(this.dataset.clockEndTime, 10) * 1000;
    }

    const closeButton = this.shadowRoot.querySelector('button.close');
    closeButton.addEventListener('click', this.onCloseClickedBound);

    // Check if the user dismissed the current message
    // We use an extremely simple, non cryptographic hash as we just want to
    // detect if the message has changed since the last time the user clicked "close".
    // We accept the extremely rare possibility of collision and cookies failing
    // as the consequence is that the user will (not) see a promo message
    // The message length is not bound, so we cannot just save the message itself into cookies
    const current = this.getCurrentMessageHash();
    const dismissed = getDismissedMessageHash();
    if (current !== dismissed) {
      // start everything only if the message has not been dismissed
      startTimer();
      addEventListener('timer-tick', this.onTimerTickBound);
      this.showBanner();
      this.render();
    }
  }

  public disconnectedCallback() {
    removeEventListener('timer-tick', this.onTimerTickBound);
    const closeButton = this.shadowRoot.querySelector('button.close');
    closeButton?.removeEventListener('click', this.onCloseClickedBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && newValue !== oldValue) {
      this.render();
    }
  }

  private render() {
    const timer = this.shadowRoot.querySelector('.timer');
    if (this.dataset.clockShow === 'true') {
      if (this.shouldShowClock()) {
        // the clock should be displayed - it is active
        timer.classList.remove('hidden');
      } else {
        // the clock was set to be displayed but the message expired already
        // hide the entire banner
        this.hideBanner();
      }
    } else {
      // the clock was disabled but we still have a message to show - hide the timer
      timer.classList.add('hidden');
    }

    const badge = this.shadowRoot.querySelector('.badge');
    if (badge) {
      badge.textContent = this.dataset.badgeText || 'Limited Time';
      if (this.dataset.badgeShow === 'true') {
        badge.classList.remove('hidden');
      }
    }

    this.renderClock(Date.now());

    updatePagePositioning();
  }

  /**
   * We have a few requirements to show the countdown clock. Calculate the decision
   */
  private shouldShowClock() {
    const currentTimestamp = Date.now();

    let startTimestamp = Number.MAX_SAFE_INTEGER;
    if (this.dataset.clockStartTime) {
      startTimestamp = parseInt(this.dataset.clockStartTime, 10) * 1000;
    }

    let endTimestamp = 0;
    if (this.dataset.clockEndTime) {
      endTimestamp = parseInt(this.dataset.clockEndTime, 10) * 1000;
    }

    return this.dataset.clockShow === 'true' &&
      startTimestamp <= currentTimestamp &&
      endTimestamp >= currentTimestamp;
  }

  private onTimerTick(event: WindowEventMap['timer-tick']) {
    this.renderClock(event.detail.timestamp);
  }

  private renderClock(timestamp: number) {
    const timer = this.shadowRoot.querySelector<HTMLElement>('.timer');
    if (!this.shouldShowClock()) {
      if (timer && !timer.classList.contains('hidden')) {
        timer.classList.add('hidden');
        updatePagePositioning();
      }

      return;
    }

    const remaining = timeLeft(timestamp, this.endTimestamp);

    this.setClockSegmentValue('.days', remaining.days);
    this.setClockSegmentValue('.hours', remaining.hours);
    this.setClockSegmentValue('.minutes', remaining.minutes);
    this.setClockSegmentValue('.seconds', remaining.seconds);

    timer?.setAttribute('aria-label', remaining.text);
  }

  private setClockSegmentValue(selector: string, value: number) {
    const stringValue = value < 10 ? '0' + value : value.toString();
    const element = this.shadowRoot.querySelector(selector);
    if (element && element.textContent !== stringValue) {
      element.textContent = stringValue;
    }
  }

  private hideBanner() {
    // this has to hide the entire header banner which should be the ancestor of this component
    const headerBanner = this.closest('.header-banner');
    if (headerBanner) {
      headerBanner.classList.add('hidden');
    }

    // ensure the whole page is repositioned
    updatePagePositioning();
  }

  private showBanner() {
    const headerBanner = this.closest('.header-banner');
    if (headerBanner) {
      headerBanner.classList.remove('hidden');
    }

    // ensure the whole page is repositioned
    updatePagePositioning();
  }

  private onCloseClicked() {
    this.hideBanner();
    const hash = this.getCurrentMessageHash();
    saveDismissedMessageHash(hash);
  }

  private getCurrentMessageHash() {
    const message = this.querySelector('[slot="message"]')?.textContent;
    return jenkinsHash(message);
  }
}

function getDismissedMessageHash() {
  try {
    return Cookie.read('top-banner-dismissed');
  } catch {
    return '';
  }
}

function saveDismissedMessageHash(hash: string) {
  const expirationTime = new Date();
  // add 4 hours to current time
  expirationTime.setTime(expirationTime.getTime() + (4 * 60 * 60 * 1000));
  writeCookie({ name: 'top-banner-dismissed', value: hash, expires: expirationTime });
}

/**
 * Very simple and fast non-cryptographic string hashing function. Returns a 32-bit
 * unsigned number (as a hex string)
 *
 * See https://en.wikipedia.org/wiki/Jenkins_hash_function#one_at_a_time for details
 * Based on https://www.burtleburtle.net/bob/hash/doobs.html the original C source is Public Domain
 *
 * Modified to work with empty and undefined inputs
 */
function jenkinsHash(key: string | undefined) {
  let hash = 0;
  const inputLength = key?.length || 0;
  for (let i = 0; i < inputLength; i++) {
    hash += key.charCodeAt(i);
    hash += hash << 10;
    // JS has no 32 bit unsigned int values, so we use the trick in the next line to
    // truncate the value to 32 bits and remove the sign
    hash = (hash & 0xFFFFFFFF) >>> 0;
    hash ^= hash >>> 6;
  }

  hash += hash << 3;
  // truncate again
  hash = (hash & 0xFFFFFFFF) >>> 0;
  hash ^= hash >>> 11;
  hash += hash << 15;

  // truncate before returning
  hash = (hash & 0xFFFFFFFF) >>> 0;
  return hash.toString(16);
}

function updatePagePositioning() {
  dispatchEvent(new Event('top-banner-updated'));
}

declare global {
  interface HTMLElementTagNameMap {
    'top-banner': TopBanner;
  }

  interface WindowEventMap {
    'top-banner-updated': Event;
  }
}

if (!customElements.get('top-banner')) {
  customElements.define('top-banner', TopBanner);
}

import './webpack-path.js';
import { appendStyleModule } from './css-dom.js';

export type Route = keyof typeof routes;

/**
 * Contains a mapping of a route handle to a route import function.
 *
 * A route handle is an easy to use reference name to refer to a particular module to import.
 *
 * This is not an exhaustive list of all modules in the code base. This only contains certain
 * modules that we want to load using the global lazy function.
 *
 * There is no need to catch import errors here. This map is only used by the lazy function which
 * handles promise rejections. Do not use `import().catch(...)` here.
 *
 * Routes must be explicit static paths. Webpack does not work well when using variables. While it
 * is possible, we try to avoid it. Most frontend frameworks also require the same. As a result,
 * we must explicitly list out every possible path. This does add some overhead to the initial file
 * size but we think it is an acceptable amount of overhead.
 */
export const routes = {
  /* eslint-disable @stylistic/max-len */
  'about-us.css': () => import('../templates/about-us.scss').then(appendStyleModule),
  'accordion-elements-section': () => import('../sections/accordion-elements/index.js'),
  'add-to-cart-elements-section': () => import('../sections/add-to-cart-elements/index.js'),
  'alert-element-section': () => import('../sections/alert/index.js'),
  'attentive-pixel': () => import('../sections/attentive-pixel.js'),
  'auth-observer': () => import('../sections/auth-observer/index.js'),
  'banner-3d-carousel-section': () => import('../sections/banner-3d-carousel/index.js'),
  'banner-element-section': () => import(/* webpackMode: "eager" */ '../sections/banner/index.js'),
  'banner-hero-full-width-section': () => import(/* webpackMode: "eager" */ '../sections/banner-hero-full-width/index.js'),
  'banner-full-width-carousel': () => import('../sections/banner-full-width-carousel/index.js'),
  'banner-side-by-side-section': () => import('../sections/banner-side-by-side/index.js'),
  'banner-tracking': () => import('../sections/banner-tracking.js'),
  'banner-why-we-are-trusted': () => import('../sections/banner-why-we-are-trusted/index.js'),
  'before-after-section': () => import('../sections/before-after/index.js'),
  'card-grid-section': () => import('../sections/card-grid/index.js'),
  'cart-drawer-section': () => import('../sections/cart-drawer/index.js'),
  'cart-init': () => import('../sections/cart-init.js'),
  'clickable-area-element-section': () => import('../sections/clickable-area/clickable-area/index.js'),
  'collection-nav-carousel-section': () => import('../sections/collection-nav-carousel/index.js'),
  'collection-page-section': () => import('../sections/collection-page/index.js'),
  'collection-pills-section': () => import('../sections/collection-pills/index.js'),
  'collection-view-observer': () => import('../sections/collection-view-observer/index.js'),
  'collection-primary': () => import('../sections/collection-primary/index.js'),
  'compare-before-after-section': () => import('../sections/compare-before-after/index.js'),
  'complex-product-comparison-section': () => import('../sections/complex-product-comparison/index.js'),
  'configurable-review-section': () => import('../sections/configurable-review/index.js'),
  'countdown-clock-section': () => import('../sections/countdown-clock/index.js'),
  'country-selector-form-element': () => import('../sections/country-selector-form/index.js'),
  'filters-drawer-section': () => import('../sections/filters-drawer/index.js'),
  'filters-sort-by-element': () => import('../sections/filters-drawer/filters-sort-by/index.js'),
  'footer-section': () => import('../sections/footer/index.js'),
  'fullstory-pixel': () => import('../sections/fullstory-pixel.js'),
  'gift-card-code-element-section': () => import('../sections/gift-card-code-element/index.js'),
  'hair-care-header': () => import('../sections/hair-care-header/index.js'),
  'hair-quiz-template': () => import('../templates/hair-quiz/index.js'),
  'header-section': () => import(/* webpackMode: "eager" */ '../sections/header/index.js'),
  'help-center-template': () => import('../templates/help-center/index.js'),
  'help-widget-section': () => import('../sections/help-widget/index.js'),
  'image-element-section': () => import(/* webpackMode: "eager" */ '../sections/image-element/index.js'),
  'installment-payment-message-element-section': () => import('../sections/installment-payment-message/index.js'),
  'klaviyo-pixel': () => import('../sections/klaviyo-pixel.js'),
  'lightbox-element-section': () => import('../sections/lightbox-element/index.js'),
  'loading-element-section': () => import('../sections/loading-element/index.js'),
  'logout-link-override': () => import('../sections/logout-link-override.js'),
  'meta-pixel': () => import('../sections/meta-pixel.js'),
  'modal-installment-payment': () => import('../sections/modal-installment-payment/index.js'),
  'modal-manager-section': () => import('../sections/modal-manager/index.js'),
  'modal-upsell-section': () => import('../sections/modal-upsell.js'),
  'notify-me-modal-section': () => import('../sections/notify-me-modal/index.js'),
  'pdp-recommendations-section': () => import('../sections/pdp-recommendations/product-recommendations/index.js'),
  'price-element-section': () => import('../sections/price/index.js'),
  'privacy-template': () => import('../templates/privacy.js'),
  'product-card-element-section': () => import('../sections/product-card/index.js'),
  'product-carousel-section': () => import('../sections/product-carousel/index.js'),
  'product-data-elements-section': () => import('../sections/product-data-elements/index.js'),
  'product-detail-countdown-timer-element-section': () => import('../sections/product-detail-countdown-timer/index.js'),
  'product-detail-section': () => import('../sections/product-detail/index.js'),
  'product-discount-message-section': () => import('../sections/product-discount-message/index.js'),
  'product-option-elements-section': () => import('../sections/product-option-elements/index.js'),
  'product-price-element-section': () => import('../sections/product-price/index.js'),
  'product-quotes': () => import('../sections/product-quotes/index.js'),
  'product-rating-stars-section': () => import('../sections/product-rating-stars/index.js'),
  'product-steps-section': () => import('../sections/product-steps/index.js'),
  'product-template': () => import('../templates/product/index.js'),
  'product-variant-select-element-section': () => import('../sections/product-variant-select/index.js'),
  'product-variant-selector-modal-section': () => import('../sections/product-variant-selector-modal/index.js'),
  'purchase-rewards-element-section': () => import('../sections/purchase-rewards-element/index.js'),
  'recommendations-carousel': () => import('../sections/recommendations-carousel/index.js'),
  'rewards-balance-section': () => import('../sections/rewards-balance/index.js'),
  'rewards-balance.css': () => import('../sections/rewards-balance/index.scss').then(appendStyleModule),
  'rewards-referrals-section': () => import('../sections/rewards-referrals/index.js'),
  'rewards-template': () => import('../templates/rewards/index.js'),
  'refer-a-friend-init': () => import('../sections/refer-a-friend-init.js'),
  'sale-banners': () => import('../sections/sale-banners.js'),
  'simple-comparison-section': () => import('../sections/simple-comparison/index.js'),
  'sentry': () => import('../sections/sentry.js'),
  'shopify-web-pixel-relay': () => import('../sections/shopify-web-pixel-relay.js'),
  'shoppable-videos.css': () => import('../sections/shoppable-videos/index.scss').then(appendStyleModule),
  'shoppable-videos-section': () => import('../sections/shoppable-videos/index.js'),
  'side-menu-section': () => import(/* webpackMode: "eager" */ '../sections/side-menu/index.js'),
  'side-menu.css': () => import('../sections/side-menu/index.scss').then(appendStyleModule),
  'sticky-cta-element-section': () => import('../sections/sticky-cta-element/index.js'),
  'trustpilot-reviews': () => import('../sections/trustpilot-reviews/index.js'),
  'twelve-days-of-gifting-section': () => import('../sections/twelve-days-of-gifting/index.js'),
  'typeform-section': () => import('../sections/typeform/index.js'),
  'user-quotes-carousel-section': () => import('../sections/user-quotes-carousel/index.js'),
  'variant-option-elements-section': () => import('../sections/variant-option-elements/index.js'),
  'yotpo-avatar-element-section': () => import('../sections/yotpo-avatar/index.js'),
  'yotpo-product-reviews-section': () => import('../sections/yotpo-product-reviews/index.js'),
  'yotpo-real-results': () => import('../sections/yotpo-real-results/index.js'),
  'yotpo-reviews-detail-actions-element-section': () => import('../sections/yotpo-reviews-detail-actions/index.js'),
  'yotpo-review-detail-element-section': () => import('../sections/yotpo-review-detail/index.js'),
  'yotpo-review-element': () => import('../sections/yotpo-review/index.js'),
  'yotpo-reviews-summary': () => import('../sections/yotpo-reviews-summary/index.js')
  /* eslint-enable @stylistic/max-len */
};

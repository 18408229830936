import { assert } from '@integrabeauty/assert';

/**
 * Input options to searching Shopify.
 *
 * @see https://shopify.dev/docs/api/ajax/reference/predictive-search#get-locale-search-suggest
 */
export interface SuggestInput {
  /**
   * The default fields searched on are title, product_type, variants.title, and vendor. For the
   * best search experience, you should search on the default field set.
   *
   * There are constraints for translated storefronts, see the docs.
   */
  fields?: Field[];

  /**
   * Limits the number of results based on limit_scope. The value can range from 1 to 10, and the
   * default is 10.
   */
  limit?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

  limit_scope?: 'all' | 'each';

  prefix?: string;

  /**
   * The search query.
   */
  query: string;

  /**
   * Specifies the type of results requested.
   *
   * The following are the accepted values, which can be combined in a comma-separated list:
   *
   * * product
   * * page
   * * article
   * * collection
   * * query
   *
   * The default value is query,product,collection,page.
   *
   * To change the default value, you can use Search Settings in the Search & Discovery app.
   */
  resource_types?: ResourceType[];

  section_id?: string;

  /**
   * Specifies whether to display results for unavailable products.
   *
   * The following are the accepted values:
   *
   * * show: Show unavailable products.
   * * hide: Hide unavailable products.
   * * last: Show unavailable products below other matching results.
   *
   * The default value is last.
   *
   * To change the default value, you can use Search Settings in the Search & Discovery app.
   *
   * This parameter is only applicable to type product.
   */
  unavailable_products?: 'hide' | 'last' | 'show';
}

export type ProductField = 'body' | 'product_type' | 'tag' | 'title' | 'variants.barcode' |
  'variants.sku' | 'variants.title' | 'vendor';
export type PageField = 'author' | 'body' | 'title';
export type ArticleField = 'author' | 'body' | 'tag' | 'title';
export type CollectionField = 'title';
export type Field = ArticleField | CollectionField | PageField | ProductField;

export type ResourceType = 'article' | 'collection' | 'page' | 'product' | 'query';

export interface Product {
  /**
   * Whether the product is available for sale (e.g. in stock)
   */
  available: boolean;

  /**
   * HTML formatted product description
   */
  body: string;

  /**
   * @example "30.00"
   */
  compare_at_price_max: string;

  /**
   * @example "30.00"
   */
  compare_at_price_min: string;

  featured_image: FeaturedImage;

  /**
   * Shopify product handle
   *
   * @example "my-product-slug"
   */
  handle: string;

  /**
   * Shopify product id
   *
   * @example 123324324
   */
  id: number;

  /**
   * Product image url
   *
   * @example "https://cdn.shopify.com/.../image.jpg?v=1234"
   */
  image: string;

  /**
   * @example "30.00"
   */
  price: string;

  /**
   * @example "30.00"
   */
  price_max: string;

  /**
   * @example "30.00"
   */
  price_min: string;

  /**
   * @example ["best-sellers", "hair-care-routine"]
   */
  tags: string[];

  /**
   * @example "My Product"
   */
  title: string;

  /**
   * @example "hair-care"
   */
  type: string;

  /**
   * @example "/products/my-handle?_pos=1&_psq=hair&_ss=e&_v=1.0"
   */
  url: string;

  /**
   * A product variant is returned only when the query matches terms specific to the variant title.
   * Only the variant with the most matching terms is returned. When a variant is returned, the
   * following product_object fields will match those of the variant:
   *
   * * featured_image
   * * image
   * * url
   *
   * cspell:ignore snowbo
   *
   * For example, a store has a snowboard with a blue variant and a light blue variant. If you
   * search for snowbo, then only the snowboard product is returned. However, if you search for
   * light blue snowbo, then the snowboard product is returned with the light blue variant.
   */
  variants: Variant[];

  /**
   * @example "my company name"
   */
  vendor: string;
}

export interface Variant {
  available: boolean;
  /**
   * might be wrong
   */
  compare_at_price: number;

  featured_image: FeaturedImage;

  id: number;
  image: string;
  /**
   * might be wrong
   */
  price: number;
  title: string;
  url: string;
}

/**
 * A query represents an auto-complete suggestion. For example, based on what someone has typed in,
 * zero or more queries will be generated that represent more specific or similar queries to
 * consider using.
 */
export interface Query {
  /**
   * @example "<mark>hair</mark><span>spray</span>"
   */
  styled_text: string;

  /**
   * @example "hairspray"
   */
  text: string;

  /**
   * @example "/search?_pos=1&_psq=hair&_ss=e&_v=1.0&q=hairspray"
   */
  url: string;
}

export interface FeaturedImage {
  alt: string;
  aspect_ratio: number;
  height: number;
  url: string;
  width: number;
}

export interface Collection {
  body: string;
  featured_image: FeaturedImage;
  handle: string;
  id: number;
}

export interface Page {
  author: string;
  body: string;
  handle: string;
  id: number;
  published_at: string;
  title: string;
  url: string;
}

export interface Article {
  author: string;
  body: string;
  handle: string;
  id: number;
  image: string;
  published_at: string;
  summary_html: string;
  tags: string[];
  title: string;
  url: string;
}

export interface ShopifySuggestResponseBody {
  resources: {
    results: {
      articles: unknown[];
      collections: Collection[];
      pages: Page[];
      products: Product[];
      queries: Query[];
    };
  };
}

export interface ShopifyErrorResponseBody {
  description: string;
  message: string;
  status: string;
}

/**
 * Represents the response body.
 *
 * In a full implementation that parsed the response body of error responses, this should actually
 * be a union of ShopifySuggestResponseBody and also ShopifyErrorResponseBody. However, we are not
 * actually leveraging ShopifyErrorResponseBody. We are just leaving the type defined for
 * documentation purposes. The promise returned by suggest only ever resolves on success so we can
 * limit the output type to the success type.
 */
export type ResponseBody = ShopifySuggestResponseBody;

/**
 * Send a search request to the Shopify api and receive a search response.
 *
 * If section id is provided, this returns a string. If section id is not provided, this returns
 * an object.
 *
 * I did not bother to make the output type derived from the presence of the section id. For now
 * the output type has to be specified via the generic value if the output should be interpreted as
 * a string. Without specifying the generic parameter this defaults to producing an object.
 *
 * @param input various search criteria
 */
export async function suggest<OutputType = ResponseBody>(input: SuggestInput) {
  assert(typeof input.query === 'string' && input.query.length, 'Invalid input');

  let path = Shopify.routes.root + 'search/suggest';
  if (!input.section_id) {
    path += '.json';
  }

  const url = new URL(path, location.href);
  url.searchParams.set('q', input.query);

  if (input.section_id) {
    url.searchParams.set('section_id', input.section_id);
  }

  if (Number.isInteger(input.limit)) {
    url.searchParams.set('resources[limit]', input.limit.toString());
  }

  if (input.limit_scope) {
    url.searchParams.set('resources[limit_scope]', input.limit_scope);
  }

  if (Array.isArray(input.resource_types) && input.resource_types.length > 0) {
    url.searchParams.set('resources[type]', input.resource_types.join(','));
  }

  if (input.prefix) {
    url.searchParams.set('options[prefix]', input.prefix);
  }

  if (Array.isArray(input.fields) && input.fields.length > 0) {
    url.searchParams.set('resources[options][fields]', input.fields.join(','));
  }

  if (input.unavailable_products) {
    url.searchParams.set('resources[options][unavailable_products]', input.unavailable_products);
  }

  let response: Response;
  try {
    response = await fetch(url);
  } catch (error) {
    const suggestError = new SuggestError((<Error>error).message);
    suggestError.input = input;
    suggestError.url = url.toString();
    throw suggestError;
  }

  // If not using section id, fetch json response body for errors including 422, 417, and 429. See
  // https://shopify.dev/docs/api/ajax/reference/predictive-search#invalid-parameter-error. We could
  // fetch and then parse these response bodies, but basically there is no need except for maybe the
  // description hint for 422. Those kinds of errors would be obvious and permanent programming
  // errors though so we would quickly get those resolves. So just recording the status in the
  // thrown error is sufficient. We do not really need to parse the body.

  if (!response.ok) {
    const suggestError = new SuggestError(
      `Received status ${response.status} fetching search results`);
    suggestError.status = response.status;
    suggestError.input = input;
    suggestError.url = url.toString();

    // Try to get the Retry-After header value and embed this in the thrown error so that we grant
    // callers the ability to check it. Accessing the header is a best effort.
    //
    // See https://shopify.dev/docs/api/ajax/reference/predictive-search#too-many-requests.
    if (response.status === 429) {
      try {
        const retryAfter = response.headers.get('Retry-After');
        const retryAfterSeconds = parseInt(retryAfter, 10);
        if (Number.isInteger(retryAfterSeconds)) {
          suggestError.retry_after = retryAfterSeconds;
        }
      } catch (error) {
        console.log(error);
      }
    }

    // For certain errors that have documented response bodies, try to fetch the response body so
    // that we can get more details into the error.
    if ([417, 422, 429, 500].includes(response.status)) {
      try {
        suggestError.response_body = await response.json();
      } catch (error) {
        console.log(error);
      }
    }

    throw suggestError;
  }

  let responseText: string;

  try {
    responseText = await response.text();
  } catch (error) {
    const suggestError = new SuggestError((<Error>error).message);
    suggestError.input = input;
    suggestError.url = url.toString();
    throw suggestError;
  }

  // If section id was provided, the response body is a string containing html formatted text that
  // was server side rendered according to the specified section liquid and the search results. We
  // just return the string. The caller may have a preference on how to parse the html or whether to
  // parse it at all, so we do not parse on the caller's behalf.

  if (input.section_id) {
    return <OutputType>responseText;
  }

  // Without a section id we are fetching from the suggest.json endpoint so we parse the results
  // and do some basic validation of the parsed object.

  let responseBody;

  try {
    responseBody = JSON.parse(responseText);
  } catch (error) {
    const suggestError = new SuggestError((<Error>error).message);
    suggestError.input = input;
    suggestError.status = response.status;
    suggestError.response_body = responseText;
    suggestError.url = url.toString();
    throw suggestError;
  }

  // We expect the type of the response body object to always be the documented type. However,
  // Shopify could change this out from under us at any time, so we keep this as a runtime check
  // instead of a hard cast.

  if (!isShopifySuggestResponseBody(responseBody)) {
    const suggestError = new SuggestError('Unexpected response body');
    suggestError.input = input;
    suggestError.status = response.status;
    suggestError.response_body = responseBody;
    suggestError.url = url.toString();
    throw suggestError;
  }

  return <OutputType>responseBody;
}

export function isShopifySuggestResponseBody(value: any): value is ShopifySuggestResponseBody {
  return typeof value === 'object' && value !== null && typeof value.resources === 'object' &&
    value.resources !== null;
}

/**
 * Represents a Shopify Suggest API error.
 */
export class SuggestError extends Error {
  /**
   * The input to the suggest function to help with diagnosing what caused the error when it might
   * be related to the input
   */
  input: SuggestInput;

  /**
   * Response status code
   *
   * @example 200
   */
  status: number;

  /**
   * The response body. This could be a string or an object or something else.
   */
  response_body: any;

  /**
   * If the status is 429, indicating a rate limit error, then the suggest function also tries to
   * access the Retry-After header and stash the value in the error.
   *
   * This is the number of seconds. The caller of suggest should make sure to delay any future calls
   * by at least this amount of time to prevent continuing to receive more 429s.
   */
  retry_after: number;

  /**
   * The request or response url involved in the request that erred.
   */
  url: string;

  constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import { getSizedImageUrl } from './shopify-theme-images.js';

/**
 * This is a lower level element that renders an image.
 *
 * This element will request a sized image from Shopify based on:
 * - either a height or width and an aspect ratio (width/height)
 * - an explicit height and width
 * - if no dimensions are specified then it will render a medium sized image by default.
 *
 * Warning: building an image and then assigning dataset attributes one-by-one will trigger
 * rerenders.
 *
 * This does not work for SVG elements.
 */
class ImageElement extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-width',
      'data-height',
      'data-aspect-ratio',
      'data-src',
      'data-alt'
    ];
  }

  readonly dataset!: {
    /**
     * Alternative text (to set the alt attribute of the image).
     */
    alt: string;

    /**
     * CSS aspect ratio.
     *
     * Floating point number. Use parseFloat not parseInt when handling.
     */
    aspectRatio: string;

    /**
     * Image height in pixels
     */
    height: string;

    /**
     * Image source url
     */
    src: string;

    /**
     * Image width in pixels
     */
    width: string;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = html;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    // Possible 'undefined' to be a string type when passed undefined value via data attribute
    if (!this.dataset.src || this.dataset.src === 'undefined') {
      return;
    }

    const height = parseInt(this.dataset.height, 10);
    const aspectRatio = parseFloat(this.dataset.aspectRatio);
    const width = parseInt(this.dataset.width, 10);
    const maxWidth = width || height * aspectRatio;

    // TODO: When the custom element is connected and the image exists, each change to certain
    // properties sometimes triggers a repaint. Avoid thrashing the dom like this. Prefer to update
    // in batch instead. For example, to perform a single batched update, create a detached image
    // element, set its properties, then replace the existing image with the new image.

    const image = this.shadowRoot.querySelector('img');
    image.style.maxWidth = `${maxWidth}px`;
    image.alt = this.dataset.alt;

    const size = this.calculateImageSize();
    image.src = getSizedImageUrl(this.dataset.src, size);
  }

  private calculateImageSize() {
    const width = parseInt(this.dataset.width, 10);
    const height = parseInt(this.dataset.height, 10);
    const aspectRatio = parseFloat(this.dataset.aspectRatio);

    // Currently making the image double the size until we can use the <picture> element and support
    // retina devices, etc
    if (width && height) {
      return `${width * 2}x${height * 2}`;
    }

    if (aspectRatio && width) {
      return `${width * 2}x${width * aspectRatio * 2}`;
    }

    if (aspectRatio && height) {
      return `${height * aspectRatio * 2}x${height * 2}`;
    }

    return 'medium';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'image-element': ImageElement;
  }
}

if (!customElements.get('image-element')) {
  customElements.define('image-element', ImageElement);
}

import { Route, routes } from './routes.js';

declare global {
  /**
   * This is a global function that can be used in liquid files to load a js module.
   *
   * Do not use this function in js files. Import and use the load function that is exported by
   * the router library instead.
   */
  // eslint-disable-next-line no-var
  var lazyLoad: typeof load;

  /**
   * This is a global function that can be used in liquid files to load a js module.
   *
   * Do not use this function in js files. Import and use the load function that is exported by
   * the router library instead.
   */
  // eslint-disable-next-line no-var
  var lazyLoadEarly: typeof load;

  /**
   * This global is "private" to this module, do not use it directly.
   *
   * To append a route from global scope (e.g. in liquid), use `lazyLoad`.
   */
  // eslint-disable-next-line no-var
  var routerRoutes: Route[];

  /**
   * This global is private to the router module. Do not use it directly.
   *
   * Modules appended to this array are loaded only by the initEarly call.
   *
   * These modules are loaded before other modules are loaded.
   */
  // eslint-disable-next-line no-var
  var routerRoutesEarly: Route[];
}

/**
 * Records which routes have been requested.
 */
const history: Record<string, any> = {};

function isValidRoute(value: any) {
  return typeof value === 'string' && value.length > 0;
}

/**
 * Triggers the dynamic import of a given route.
 *
 * Webpack works better when using dynamic imports that use a static path. As such, this looks up
 * modules by name in a map that contains a function that contains a dynamic import with a static
 * path instead of just using `import(pathVariable)`.
 *
 * This function is written so as to ensure that the failure to load any given module does not
 * prevent other modules from loading.
 *
 * This suppresses import errors. Import errors are logged as a side effect.
 *
 * @todo consider adding retries to handler
 */
export function load(route: Route) {
  if (!isValidRoute(route)) {
    console.warn('Invalid route', route);
    return;
  }

  if (route in history) {
    console.warn('Duplicate route', route);
    return;
  }

  history[route] = 1;

  const handler = routes[route];
  if (!handler) {
    console.warn('Unknown route', route);
    return;
  }

  handler().catch(console.warn);
}

/**
 * In order to properly initialize the router, callers must call both initEarly and initLate.
 *
 * This function should be called before loading other modules. By calling this earlier, these
 * modules are queued up for loading before other modules. While that does not guarantee that they
 * load any faster, it tends to happen. It is basically an attempt at providing a hint to the
 * browser to load certain modules in order, even though modules are loaded async.
 *
 * Loads are in 3 categories, early, middle, and late. Users of this library should call
 * initEarly, then possibly load some other modules, then call initLate.
 */
export function initEarly() {
  for (const route of window.routerRoutesEarly || []) {
    load(route);
  }

  window.lazyLoadEarly = load;
  window.routerRoutesEarly = [];
}

/**
 * In order to properly initialize the router, callers must call both initEarly and initLate.
 *
 * This function should be called after loading other modules. This loads at what is considered a
 * normal or low priority. Basically, at the back of the queue. While async loads are not guaranteed
 * to load in a specific order, loads that are queued up earlier tend to load earlier.
 */
export function initLate() {
  for (const route of window.routerRoutes || []) {
    load(route);
  }

  window.lazyLoad = load;
  window.routerRoutes = [];
}

import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * @todo provide a summary description of this element
 */
class SearchProductPreview extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * Product thumb URL
     */
    imageUrl: string;

    /**
     * Product title
     */
    title: string;
  };

  public constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const imageEl = this.shadowRoot.querySelector<HTMLImageElement>('img');
    imageEl.src = this.dataset.imageUrl;
    imageEl.alt = this.dataset.title;

    const titleEl = this.shadowRoot.querySelector<HTMLElement>('span.title');
    titleEl.textContent = this.dataset.title;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'search-product-preview': SearchProductPreview;
  }
}

if (!customElements.get('search-product-preview')) {
  customElements.define('search-product-preview', SearchProductPreview);
}
